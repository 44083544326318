// import { getCitiesAutocompletion } from '@/api/google-api'

// import { COUNTRIES } from '@/constants/countries'

// import useApi from './useApi'

// export default function useCitiesAutocompletionApi() {
//   return useApi<
//     { placeId: string; city: string }[],
//     { query: string; country: string }
//   >(async params => {
//     if (!params?.query) return []

//     const { query, country } = params

//     const code = COUNTRIES.find(({ name }) => name === country)?.code
//     if (!code) throw new Error(`Can not find country code for ${country}`)

//     const result =
//       (await getCitiesAutocompletion(query, code)).predictions || []

//     return result.map(({ description, structured_formatting, place_id }) => ({
//       placeId: place_id,
//       city: description || structured_formatting.main_text,
//     }))
//   })
// }

import { getCitiesAutocompletion } from '@/api/google-api'
import { COUNTRIES } from '@/constants/countries'
import useApi from './useApi'

export default function useCitiesAutocompletionApi() {
  return useApi<
    { placeId: string; city: string }[],
    { query: string; country: string }
  >(async params => {
    if (!params?.query) return []

    const { query, country } = params

    const code = COUNTRIES.find(({ name }) => name === country)?.code
    if (!code) throw new Error(`Cannot find country code for ${country}`)

    // Використовуємо тип AutocompletePrediction без імпорту
    const predictions: google.maps.places.AutocompletePrediction[] = await getCitiesAutocompletion(
      query,
      code
    )

    return predictions.map(
      ({ description, structured_formatting, place_id }) => ({
        placeId: place_id,
        city: description || structured_formatting.main_text,
      })
    )
  })
}
