import { UserPaper, UserType } from '@/models/user'

import {
  getInitialCommonModuleState,
  getInitialDataModuleState,
  getMultipleUpdatesModuleState,
  getPagintaionModuleState,
} from '@/helpers/store-state'

import {
  UserDetailsModule,
  UserTripsModule,
  State,
  MisconductionsModule,
  UserInsuranceDetailsModule,
} from '.'

export const getInitialUserDetailsState = (): UserDetailsModule => ({
  ...getInitialDataModuleState(),

  statusUpdating: getInitialCommonModuleState(),

  papersVerifying: {
    [UserPaper.DRIVER_LICENSE]: getInitialCommonModuleState(),
    [UserPaper.PASSPORT_OR_ID]: getInitialCommonModuleState(),
  },

  passwordReset: getInitialCommonModuleState(),

  updating: getMultipleUpdatesModuleState(),
})

export const getInitialUserInsuranceDetailsState = (): UserInsuranceDetailsModule => ({
  ...getInitialDataModuleState(),
  statusUpdating: getInitialCommonModuleState(),
  updating: getMultipleUpdatesModuleState(),
  creatingLoading: false,
})

export const getInitialUserTripsState = (): UserTripsModule => ({
  [UserType.HOST]: getInitialDataModuleState(),
  [UserType.RENTER]: getInitialDataModuleState(),
})

export const getInitialMisconductsState = (): MisconductionsModule => ({
  creation: getInitialCommonModuleState(),
  listModule: getPagintaionModuleState(),
})

export const getInitialUsersState = (): State => ({
  listModule: getPagintaionModuleState(),

  usersDetails: {},
  userTrips: {},
  usersVehicles: {},
  usersMisconductions: {},
  userInsurancePolicy: {},
})
