import pick from 'lodash/pick'
import map from 'lodash/map'

import { User } from '@/models/user'

import { commonCase } from '@/helpers/strings'

export const formatUserForTheTable = (
  user: User,
  pickedKeys: (keyof User)[]
) => {
  const picked = pick(user, pickedKeys)

  return map(picked, (value, key) => ({
    key,
    value,
    label: commonCase(key, true),
  }))
}
